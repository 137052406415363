import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useMenu } from '../../../hooks'
import { config } from '../../../config'

const FurtherReading = ({ testID = '', data = [] }) => {
  const c = data
    .filter((item) => item.type === 'article')
    .sort((a, b) => a.order > b.order)
    .slice(0, 5)

  const goMenu = useMenu()

  return (
    <div data-testid={testID} className={`FurtherReading`}>
      <div className={`reading-content`}>
        <h4>{config.readMore}</h4>
        <ul>
          {c.map((item, index) => {
            const t = <a href={item.slug}>{item.title.substring(0, 35)}...</a>
            return <li onClick={() => goMenu(item.slug)}>{t}</li>
          })}
        </ul>
      </div>
    </div>
  )
}

FurtherReading.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.array
}

export default FurtherReading
