import PropTypes from 'prop-types'
import { config } from '../../../config'

const Swoosh = ({ testID, swooshType, position }) => {
  const swoosh = config.swoosh[swooshType]
  return (
    <img
      data-testid={testID}
      src={swoosh}
      alt={'swoosh'}
      className={'swooshed'}
      style={{ height: 500 }}
    />
  )
}

const swooshTypes = ['white-swoosh', 'blue-swoosh']

Swoosh.propTypes = {
  testID: PropTypes.string,
  swooshType: PropTypes.oneOf(swooshTypes)
}

Swoosh.defaultProps = {
  testID: `Swoosh-${Math.floor(Math.random() * 90000) + 10000}`,
  swooshType: 'blue-swoosh'
}

export default Swoosh
