import PropTypes from 'prop-types'
import IntroductionText from '../../atoms/IntroductionText'
import Heading from '../../atoms/Heading'
import TextParagraph from '../../atoms/TextParagraph'
import SideImage from '../../atoms/SideImage'
import ButtonBar from '../ButtonBar'
import { config } from '../../../config'
import Swoosh from '../../atoms/Swoosh'

const Section = ({
  testID,
  type,
  position,
  introText,
  paragraphTitle,
  content,
  image,
  buttonBar
}) => {
  const hType = type === 'header' ? 'h1' : 'h2'
  const imgPosition = position === 'left' ? 'right' : 'left'
  const swooshColor = type === 'slogan' ? 'white-swoosh' : 'blue-swoosh'

  const renderSectionContent = () => {
    return (
      <div className={`section-content`}>
        {introText && <IntroductionText content={introText} />}
        {paragraphTitle && <Heading type={hType} text={paragraphTitle} />}
        {content && <TextParagraph content={content} />}
        {buttonBar && <ButtonBar buttons={buttonBar} />}
      </div>
    )
  }

  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`Section`}
    >
      {type !== 'slogan' && (
        <div
          className={`mobile-image`}
          style={{ backgroundImage: `url('${image}')` }}
        >
          {/*<Swoosh position={imgPosition} swooshType={swooshColor} />*/}
        </div>
      )}

      {position === 'left' && renderSectionContent()}
      <div style={{ flex: 1 }}>
        <SideImage
          image={image}
          position={imgPosition}
          swooshType={swooshColor}
        />
      </div>
      {position === 'right' && renderSectionContent()}
    </div>
  )
}

const exceptionClasses = ['header', 'slogan', 'article']
const positions = ['left', 'right']

Section.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.oneOf(exceptionClasses),
  position: PropTypes.oneOf(positions),
  introText: PropTypes.string,
  paragraphTitle: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string
}

Section.defaultProps = {
  testID: `Section-${Math.floor(Math.random() * 90000) + 10000}`,
  type: `header`,
  position: `left`,
  introText: null,
  paragraphTitle: null,
  content: null,
  image: null
}

export default Section
