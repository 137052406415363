import PropTypes from 'prop-types'
import Swoosh from '../Swoosh'

const SideImage = ({
  testID = '',
  position = 'right',
  swooshType = 'blue-swoosh',
  image
}) => {
  const style = {
    backgroundImage: `url(${image})`
  }

  return (
    <div
      data-testid={testID}
      data-object-type={position ?? ''}
      className={`SideImage`}
      style={style}
    >
      <div className={`swoosh-container`}>
        <Swoosh position={position} swooshType={swooshType} />
      </div>
    </div>
  )
}

const exceptionClasses = ['left', 'right']
const colorStyle = ['white-swoosh', 'blue-swoosh']

SideImage.propTypes = {
  testID: PropTypes.string,
  position: PropTypes.oneOf(exceptionClasses),
  swooshType: PropTypes.oneOf(colorStyle)
}

export default SideImage
