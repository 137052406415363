import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../context'

const useMenu = () => {
  const ctx = useContext(AppContext)
  const nav = useNavigate()

  const navTo = (slug) => {
    ctx.setMenu(false)
    nav(slug)
  }

  return navTo
}

export default useMenu
