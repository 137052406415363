import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { config } from '../../../config'
const HelmetSrc = ({ testID = '', title = '', description = '' }) => {
  return (
    <Helmet testID={testID}>
      <title>{`${config.siteName} :: ${title}`}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta name='twitter:creator' content={config.siteName} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
    </Helmet>
  )
}

Helmet.propTypes = {
  testID: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}

export default HelmetSrc
