import { useContext } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from '../../../context'
import ListMenu from '../ListMenu'

const MenuOverlay = ({ testID = '' }) => {
  const ctx = useContext(AppContext)

  return (
    <div id='MenuOverlay' data-testid={testID} className={`MenuOverlay`}>
      <ListMenu content={ctx.content} />
    </div>
  )
}

MenuOverlay.propTypes = {
  testID: PropTypes.string
}

export default MenuOverlay
