import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Logo from '../../atoms/Logo'

const Header = ({ testID = '' }) => {
  return (
    <div data-testid={testID} className={`Header`}>
      <Logo />
    </div>
  )
}

Header.propTypes = {
  testID: PropTypes.string
}

export default Header
