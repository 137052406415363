import PropTypes from 'prop-types'

const Heading = ({ testID, type, text }) => {
  const CustomTag = type?.replace(' ', '')
  return (
    <CustomTag
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`Heading`}
    >
      {text}
    </CustomTag>
  )
}

const exceptionClasses = ['h1', 'h2', 'h3', 'h4']

Heading.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.oneOf(exceptionClasses),
  text: PropTypes.string
}

export default Heading
