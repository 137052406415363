import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Hamburger from 'hamburger-react'

import { AppContext } from '../../../context'

const MenuTrigger = ({ testID = '' }) => {
  const ctx = useContext(AppContext)

  return (
    <div data-testid={testID} className={`MenuTrigger`}>
      <Hamburger
        toggled={ctx?.showMenu}
        toggle={ctx?.setMenu}
        color='#3D8ACB'
        size={62}
      />
    </div>
  )
}

MenuTrigger.propTypes = {
  testID: PropTypes.string
}

export default MenuTrigger
