import PropTypes from 'prop-types'
import { config } from '../../../config'
import { useMenu } from '../../../hooks'

const Logo = ({ testID = '', logoType = 'blue-dot' }) => {
  const logo = config.logo[logoType]
  const goMenu = useMenu()

  return (
    <div data-testid={testID} className={`Logo`} onClick={() => goMenu('/')}>
      <img src={logo} alt={config.siteName} /> 
    </div>
  )
}

const logoTypes = ['blue-dot', 'white-dot']

Logo.propTypes = {
  testID: PropTypes.string,
  logoType: PropTypes.oneOf(logoTypes)
}

export default Logo
