import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IntroductionText from '../../atoms/IntroductionText'
import Heading from '../../atoms/Heading'
import TextParagraph from '../../atoms/TextParagraph'
import ButtonBar from '../ButtonBar'
import SideImage from '../../atoms/SideImage'
import FurtherReading from '../../molecules/FurtherReading'

const Article = ({ testID = '', article = {}, content = [] }) => {
  const { introContent, title, contentShort, contentLong, img } = article

  const renderContent = () => {
    return (
      <div className={`article-content-list`}>
        {contentLong?.map((item, index) => {
          const r =
            item.type === 'image' ? (
              <img src={item.content} alt={``} />
            ) : (
              <TextParagraph content={item.content} />
            )
          return <div key={`content-${index}`}>{r}</div>
        })}
      </div>
    )
  }

  return (
    <div data-testid={testID} className={`Article`}>
      <div
        className={`mobile-image`}
        style={{ backgroundImage: `url('${img}')` }}
      ></div>

      <div className={`article-content`}>
        {introContent && <IntroductionText content={introContent} />}
        {title && <Heading type={`h2`} text={title} />}
        {contentShort && (
          <div className={`article-intro`}>
            <TextParagraph content={contentShort} />
          </div>
        )}
        {renderContent()}
      </div>

      <div
        style={{
          flex: 1,
          display: 'flex',
          gap: 20,
          flexDirection: 'column',
          marginBottom: 260
        }}
      >
        <SideImage image={img} position={'right'} swooshType={`blue-swoosh`} />
        <FurtherReading data={content} />
      </div>

      <div className={`mobile-readmore`}>
        <FurtherReading data={content} />
      </div>
    </div>
  )
}

Article.propTypes = {
  testID: PropTypes.string,
  article: PropTypes.object
}

export default Article
