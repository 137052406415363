import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import ListSections from '../../organisms/ListSections'
import { useHomepage } from '../../../hooks'

const Homepage = ({ testID = '', data = [] }) => {
  const content = useHomepage(data)

  return (
    <Fade bottom distance={'30px'} delay={100}>
      <div data-testid={testID} className={`Homepage`}>
        <ListSections sections={content} startWith={'right'} />
      </div>
    </Fade>
  )
}

Homepage.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.array
}

export default Homepage
