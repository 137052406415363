import { useState } from 'react'
import { AppContext } from './context'
import { useDatabase } from './hooks'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Navigation from './components/organisms/Navigation'
import Homepage from './components/pages/Homepage'
import Detail from './components/pages/Detail'

const App = () => {
  const [content, isLoaded] = useDatabase('articles')
  const [showMenu, setMenu] = useState(false)

  const renderContent = () => {
    if (isLoaded) {
      return (
        <Routes>
          <Route path='/' element={<Navigation />}>
            <Route index element={<Homepage data={content} />} />
            <Route path='/:slug' element={<Detail data={content} />} />
          </Route>
        </Routes>
      )
    }
  }

  return (
    <HelmetProvider>
      <AppContext.Provider value={{ content, showMenu, setMenu }}>
        <BrowserRouter>{renderContent()}</BrowserRouter>
      </AppContext.Provider>
    </HelmetProvider>
  )
}

export default App
