import logoBlueDot from '../resources/img/blue-sky-blue-dot.svg'
import logoWhiteDot from '../resources/img/blue-sky-white-dot.svg'
import swooshBlue from '../resources/img/blue-swoosh.svg'
import swooshWhite from '../resources/img/white-swoosh.svg'

const siteName = 'Blue Sky UCS'

const config = {
  logo: { 'white-dot': logoWhiteDot, 'blue-dot': logoBlueDot },
  swoosh: { 'blue-swoosh': swooshBlue, 'white-swoosh': swooshWhite },
  siteName,
  readMore: 'Lees verder'
}

export default config
