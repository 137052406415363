import PropTypes from 'prop-types'
import Button from '../../atoms/Button'

const ButtonBar = ({ testID = '', buttons }) => {
  return (
    <div data-testid={testID} className={`ButtonBar`}>
      {buttons.map((button, index) => {
        if (button.label) {
          return <Button key={`btn-${index}`} {...button} />
        }
      })}
    </div>
  )
}

ButtonBar.propTypes = {
  testID: PropTypes.string,
  buttons: PropTypes.array
}

ButtonBar.defaults = {
  testID: `ButtonBar-${Math.floor(Math.random() * 90000) + 10000}`
}

export default ButtonBar
