import { useContext } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { config } from '../../../config'
import { AppContext } from '../../../context'
import Helmet from '../../molecules/Helmet'
import Header from '../../molecules/Header'
import Footer from '../../molecules/Footer'

import { useSlug } from '../../../hooks'
import MenuTrigger from '../../atoms/MenuTrigger'
import MenuOverlay from '../../organisms/MenuOverlay'
import Fade from 'react-reveal/Fade'

const Navigation = () => {
  const { content, showMenu } = useContext(AppContext)
  const location = useLocation().pathname
  const page = useSlug(location, content)

  return (
    <div className={`Navigation`}>
      <Helmet
        title={page?.title}
        description={`${config.siteName} :: ${page?.contentShort}`}
      />
      <Header />
      {!showMenu && <Outlet />}

      <Footer />
      {showMenu && <MenuOverlay />}
      <MenuTrigger />
    </div>
  )
}

export default Navigation
