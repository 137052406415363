import PropTypes from 'prop-types'
import Section from '../../molecules/Section'

const ListSections = ({ testID = '', sections = [], startWith = 'left' }) => {
  let position = startWith

  return (
    <div data-testid={testID} className={`ListSections`}>
      {sections.map((item, index) => {
        position = position === 'right' ? 'left' : 'right'
        return (
          <Section
            key={`section-${index}`}
            type={item.type}
            position={position}
            introText={item.introContent}
            paragraphTitle={item.title}
            image={item.img}
            content={item.contentShort}
            buttonBar={[item.leftButton, item.rightButton]}
          />
        )
      })}
    </div>
  )
}

const positions = ['left', 'right']

ListSections.propTypes = {
  testID: PropTypes.string,
  sections: PropTypes.array,
  startWith: PropTypes.oneOf(positions)
}

export default ListSections
