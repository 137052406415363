import PropTypes from 'prop-types'

const IntroductionText = ({ testID = '', content = '' }) => {
  return (
    <div data-testid={testID} className={`IntroductionText`}>
      {content}
    </div>
  )
}

IntroductionText.propTypes = {
  testID: PropTypes.string,
  content: PropTypes.string
}

export default IntroductionText
