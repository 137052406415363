import PropTypes from 'prop-types'
import Logo from '../../atoms/Logo'

const Footer = ({ testID = '' }) => {
  return (
    <div data-testid={testID} className={`Footer`}>
      <div>
        <Logo logoType={'white-dot'} />
      </div>
      <div>
        <ul>
          <li>
            <a href={'mailto:info@blueskyucs.eu'}>info@blueskyucs.eu</a>
          </li>
          <li>Poststraat 2b</li>
          <li>6135 KR Sittard</li>
          <li>
            <a href={'tel:+31654944055'}>+31 (0)6 54 94 40 55</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

Footer.propTypes = {
  testID: PropTypes.string
}

export default Footer
