import PropTypes from 'prop-types'

const Button = ({ testID, label, action }) => {
  const clickHandler = () => {
    action()
  }

  return (
    <a data-testid={testID} className={`Button`} href={action}>
      {label}
    </a>
  )
}

Button.propTypes = {
  testID: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.string /** Function to execute */
}

Button.defaultProps = {
  testID: `Button-${Math.floor(Math.random() * 90000) + 10000}`,
  label: 'Button',
  action: null
}

export default Button
