import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { usePermanent, useSlug } from '../../../hooks'
import ListSections from '../../organisms/ListSections'
import Article from '../../molecules/Article'

const Detail = ({ testID = '', data = [] }) => {
 
  const perm = usePermanent(data)
  const location = useLocation().pathname
  const page = useSlug(location, data)

  return (
    <Fade bottom distance={'30px'} delay={100}>
      <div data-testid={testID} className={`Detail`}>
        <Article article={page ? page : data[2]} content={data} />
        <div className={`separator`}>
          <ListSections sections={perm} startWith={'left'} />
        </div>
      </div>
    </Fade>
  )
}

Detail.propTypes = {
  testID: PropTypes.string,
  data: PropTypes.array
}

export default Detail
