import PropTypes from 'prop-types'
import Logo from '../../atoms/Logo'
import Fade from 'react-reveal/Fade'

import { useMenu } from '../../../hooks'

const ListMenu = ({ testID = '', content = [] }) => {
  const goMenu = useMenu()

  return (
    <div data-testid={testID} className={`ListMenu`}>
      <div style={{ width: '100%' }}>
        <Logo />
      </div>
      <div className={`container`}>
        {content.map((item, index) => {
          if (item.type !== 'article') return null
          return (
            <div className={`menu-item`} onClick={() => goMenu(item.slug)}>
              <Fade bottom distance={'30px'} delay={index * 100}>
                <h5>{item.title}</h5>
              </Fade>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ListMenu.propTypes = {
  testID: PropTypes.string,
  content: PropTypes.array
}

export default ListMenu
